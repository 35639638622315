.videoCard {
  width: 440px !important;
}

.heroVideo {
  width: 100% !important;
  height: 560px !important;
  border-radius: 0 !important;
}

.carouselExplore {
  :global {
    .slick-prev {
      left: -20px;
    }
    .slick-next {
      right: -20px;
    }
  }
}

.countryImg {
  height: 568px !important;
}
