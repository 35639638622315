.featureBigImage {
  display: block;
  position: relative;
  width: 100%;
  height: 240px;
  flex-grow: 1;

  @media (min-width: theme('screens.md')) {
    height: 800px;
  }
}

.featureBigImage2 {
  display: block;
  position: relative;
  width: 100%;
  height: 400px;

  @media (min-width: theme('screens.md')) {
    height: 800px;
  }
}

.featureSmallBigImage2 {
  display: block;
  position: relative;
  width: 100%;
  height: 192px;

  @media (min-width: theme('screens.md')) {
    height: 385px;
  }
}

.featureSmallImage {
  display: block;
  position: relative;
  width: 100%;
  height: 160px;

  @media (min-width: theme('screens.md')) {
    height: 385px;
  }
}

.featureSmallBigImage {
  display: block;
  position: relative;
  width: 100%;
  height: 240px;

  @media (min-width: theme('screens.md')) {
    height: 385px;
  }
}

:global {
  .md {
    :local {
      .featureBigImage {
        @media (min-width: theme('screens.md')) {
          height: 600px;
        }
      }

      .featureSmallBigImage,
      .featureSmallImage {
        @media (min-width: theme('screens.md')) {
          height: 285px;
        }
      }
    }
  }
}

.featureSmallImageV2,
.featureSmallBigImageV2,
.featureBigSmallImageV2,
.featureBigImageV2 {
  border-radius: theme('borderRadius.lg');
  color: theme('colors.brand.navy');
  &:hover {
    color: theme('colors.brand.navy');
  }
}

.featureTitle {
  line-height: 1.1;
}

.featureBigImageV2 {
  img {
    height: 220px !important;

    @media(min-width: theme('screens.md')) {
      height: 320px !important;
    }
  }

  .featureTitle {
    height: 66px;
    line-height: 1.1;
    font-size: theme('fontSize.xl');
    width: 280px;
    margin: 0 auto;

    @media(min-width: theme('screens.md')) {
      width: 100%;
      height: 52px;
      line-height: 1.1;
      font-size: theme('fontSize.2xl');
    }
  }
}

.featureSmallBigImageV2 {
  img {
    height: 227px !important;
  }

  .featureTitle {
    height: 52px;
    line-height: 1.1;
    font-size: theme('fontSize.xl');

    @media(min-width: theme('screens.md')) {
      line-height: 1.1;
      font-size: theme('fontSize.2xl');
    }
  }
}

.featureSmallImageV2 {
  img {
    height: 104px !important;

    @media(min-width: theme('screens.md')) {
      height: 200px !important;
    }
  }

  .featureTitle {
    height: 66px;
    line-height: 1.1;
    font-size: theme('fontSize.xl');

    @media(min-width: theme('screens.md')) {
      height: 79px;
      font-size: theme('fontSize.2xl');
    }
  }
}

.featureBigSmallImageV2 {
  img {
    height: 104px !important;

    @media(min-width: theme('screens.md')) {
      height: 200px !important;
    }
  }

  .featureTitle {
    height: 66px;
    line-height: 1.1;
    font-size: theme('fontSize.xl');
    margin: 0 auto;

    @media(min-width: theme('screens.md')) {
      width: 100%;
      height: 79px;
      font-size: theme('fontSize.2xl');
    }
  }

  @media (min-width: theme('screens.md')) {
    img {
      height: 320px !important;
    }

    .featureTitle {
      height: 52px;
      line-height: 1.1;
      font-size: theme('fontSize.2xl');
    }
  }
}
