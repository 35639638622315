.heroImage {
  border: 2px solid theme('colors.brand.green');
  border-radius: theme('borderRadius.lg');
  height: 360px !important;
  object-fit: cover;

  @media(min-width: theme('screens.md')) {
    height: 540px !important;
  }
}

.cardImage {
  height: 280px !important;

  @media (min-width: theme('screens.md')) {
    height: 320px !important;
  }
}

.heroImgContainer {
  height: 360px;
  position: relative;
  display: block !important;

  @media (min-width: theme('screens.md')) {
    height: 640px;
  }
}
